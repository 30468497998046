
import { defineStore } from 'pinia'
import { songsStore } from "./songs"

export const mainStore = defineStore('main', {
  state: () => {
    return {
      content: "HelloWorld",
      count: 0,
      phone: "18136003456"
    }
  },
  getters: {
    /* 使用state */
    // phoneHidden(state) {
    //   console.log("🚀 ~ file: index.ts ~ line 13 ~ phoneHidden ~ state", state)
    //   return state.phone.toString().replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    // }

    /* 使用this */
    phoneHidden(): String {
      // console.log("🚀 ~ file: index.ts ~ line 13 ~ phoneHidden ~ state", this.phone)
      return this.phone.toString().replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    },

    /* 调用别的store的action */
    getSongsList(): String[] {
      return songsStore().songs

    }
  },
  actions: {
    changeAllState() {
      this.count++
      this.content = "actions====修改===content"
    }
  }
})