import { defineComponent } from "vue";
import batbLogo from "@/assets/batb.png";
import moment from "moment";
export default defineComponent({
    name: "Home",
    data() {
        return {
            beginTime: "2022-01-02",
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            batbLogo,
        };
    },
    mounted() {
        this.getTogetherTime();
        /* 每天请求一次 */
        this.getToDayLove();
    },
    methods: {
        /* 打印机文字 */
        text(str) {
            var index = 0;
            var arr = [];
            for (var i = 0; i < str.length; i++) {
                arr[i] = str[i];
            }
            const p = document.getElementById("sp1");
            const sp2 = document.getElementById("sp2");
            var timer_1 = setInterval(function () {
                sp2.style.display = "none";
                setTimeout("sp2.style.display='inline'", 500);
            }, 700);
            var timer_2 = setInterval(function () {
                if (index < arr.length) {
                    var text_1 = document.createTextNode(arr[index]);
                    p.appendChild(text_1);
                    index++;
                }
                else {
                    sp2.style.display = "none";
                    clearInterval(timer_1);
                    clearInterval(timer_2);
                }
            }, 300);
        },
        getTogetherTime() {
            setInterval(() => {
                this.days = moment().diff(moment(this.beginTime), "days");
                this.hours = moment().hours();
                this.minutes = moment().minutes();
                this.seconds = moment().seconds();
            }, 1000);
        },
        /* 获取每日情话 */
        getToDayLove() {
            // const lastSendTime = localStorage.getItem("toDayLoveTime");
            // if (
            //   !lastSendTime ||
            //   moment(lastSendTime).isBefore(moment().format("YYYY-MM-DD")) ||
            //   !localStorage.getItem("toDayLove")
            // ) {
            //   getToDayLove({}).then((res: MyResponse) => {
            //     const { code = "", message = "" } = res;
            //     if (Number(code) > 0) {
            //       let loveText = ""
            //     if (typeof (message) === "string") {
            //       loveText=message
            //     } else if (typeof message?.data === "object") {
            //       const { text, type } = message?.data;
            //       loveText=text
            //     }
            //       /* 打印机显示文字 */
            //       this.text(loveText);
            //       localStorage.setItem(
            //         "toDayLoveTime",
            //         moment().format("YYYY-MM-DD").toString()
            //       );
            //       localStorage.setItem("toDayLove", loveText);
            //     }
            //   }).catch((err) => {
            //     this.text("我不知道该说什么，只是突然在这一刻很想你~");
            //   });
            // } else {
            //   this.text(localStorage.getItem("toDayLove") || "");
            // }
            const textArr = ["我不知道该说什么，只是突然在这一刻很想你~", "你的名字，是我见过最短的情诗"];
            // 随机获取textArr中的一个元素
            const loveText = textArr[Math.floor(Math.random() * textArr.length)];
            /* 打印机显示文字 */
            this.text(loveText);
        },
    },
});
