/* 
  无法使用commonjs 导出，因为组件采用了 es6导入。混用报错
*/

import Test from '../components/pages/MyTest/Test.vue'
import Test2 from '../components/pages/MyTest/Test2.vue'



const routes = [

  {
    path: '/test',     //测试页面1
    name: 'test',
    component: Test
  },
  {
    path: '/test2',     //测试页面1
    name: 'test2',
    component: Test2
  },
]


// export { routes }

export default routes




