import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "dialog-footer" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_el_input = _resolveComponent("el-input");
    const _component_el_form_item = _resolveComponent("el-form-item");
    const _component_el_form = _resolveComponent("el-form");
    const _component_el_button = _resolveComponent("el-button");
    const _component_el_dialog = _resolveComponent("el-dialog");
    return (_openBlock(), _createBlock(_component_el_dialog, {
        modelValue: _ctx.addSongModal,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.addSongModal) = $event)),
        title: "新增歌曲",
        width: "500px"
    }, {
        footer: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.addConfirm
                }, {
                    default: _withCtx(() => [
                        _createTextVNode("确定")
                    ]),
                    _: 1
                }, 8, ["onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_el_form, { model: _ctx.form }, {
                default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                        label: "歌曲名",
                        "label-width": "100px"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                                modelValue: _ctx.form.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.form.name) = $event)),
                                autocomplete: "off",
                                style: { "width": "300px" }
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                        label: "歌手",
                        "label-width": "100px"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                                modelValue: _ctx.form.author,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.form.author) = $event)),
                                autocomplete: "off",
                                style: { "width": "300px" }
                            }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }, 8, ["model"])
        ]),
        _: 1
    }, 8, ["modelValue"]));
}
