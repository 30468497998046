import { defineComponent, ref } from "vue";
import { addNewSong, updateSong } from '@/api/songs';
export default defineComponent({
    name: "AddSong",
    setup(props, { attrs, slots, emit }) {
        const addSongModal = ref(false);
        const songtype = ref("");
        const form = ref({ name: "", author: "" });
        /* 新增 修改歌曲 */
        const addConfirm = () => {
            if (songtype.value === "修改") {
                updateSong(Object.assign({}, form.value)).then((res) => {
                    const { code = "", message = [] } = res;
                    if (Number(code) > 0) {
                        emit("getAllSongs", {});
                        emit("updateLocalStorage", {});
                        resetData();
                    }
                });
            }
            else {
                addNewSong(Object.assign({}, form.value)).then((res) => {
                    const { code = "", message = [] } = res;
                    if (Number(code) > 0) {
                        emit("getAllSongs", {});
                        emit("updateLocalStorage", {});
                        resetData();
                    }
                });
            }
        };
        const resetData = () => {
            form.value = { name: "", author: "" };
            addSongModal.value = false;
        };
        const showAddSongModal = (songInfo = {}) => {
            addSongModal.value = true;
            if (Object.keys(songInfo).length === 0) {
                songtype.value = "新增";
                form.value = { name: "", author: "" };
            }
            else {
                songtype.value = "修改";
                form.value = Object.assign(Object.assign(Object.assign({}, form.value), songInfo), { id: songInfo._id });
            }
        };
        return {
            addSongModal,
            form,
            addConfirm,
            showAddSongModal,
        };
    },
});
