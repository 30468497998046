import myAxios from '@/util/request';

const prefix = "songs";


/* 获取歌曲列表 */
export function queryAllSongs(params: Record<string,unknown>):Promise<Record<string,unknown>> {
  return myAxios({
    url: `${prefix}/queryAllSongs`,
    method: 'post',
    data: params

  })
}


/* 新增音乐 */
export function addNewSong(params: Record<string,unknown>):Promise<Record<string,unknown>> {
  return myAxios({
    url: `${prefix}/addNewSong`,
    method: 'post',
    data: params

  })
}

/* 删除音乐 */
export function deleteSong(params: Record<string,unknown>):Promise<Record<string,unknown>> {
  return myAxios({
    url: `${prefix}/deleteSong`,
    method: 'post',
    data: params

  })
}

/* 修改音乐 */
export function updateSong(params: Record<string,unknown>):Promise<Record<string,unknown>> {
  return myAxios({
    url: `${prefix}/updateSong`,
    method: 'post',
    data: params

  })
}


