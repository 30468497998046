import { createApp } from 'vue'
import "./qiankun"

import App from './App.vue'
import { router } from "./router"
import * as Icons from "@element-plus/icons-vue"
import { createPinia } from 'pinia'

const app = createApp(App)
// 创建pinia 实例
const pinia = createPinia()
// 挂载到 Vue 根实例上
app.use(pinia)
app.use(router)

// 注册全局组件
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key as keyof typeof Icons])
})
// app.component('edit', Edit)
app.mount('#app')
