import { defineComponent } from "vue";
import { queryAllSongs } from '@/api/songs';
export default defineComponent({
    name: "App",
    data() {
        return {
        // allSongs: [] as string[],
        };
    },
    created() {
        this.getAllSongs();
        //开始监听鼠标点击事件
        window.addEventListener("click", (e) => {
            this.generateLove(e);
        });
    },
    methods: {
        getAllSongs() {
            queryAllSongs({}).then((res) => {
                const { code = "", message = [] } = res;
                if (Number(code) > 0) {
                    localStorage.setItem("mySongs", JSON.stringify([...message.map((item) => item.name)]));
                }
            })
                .catch((err) => { });
        },
        generateLove(e) {
            let init_content = ["星辰大海", "关于你的梦", "晚星"]; //自定义内容的数组
            let content = [...init_content];
            const localSongs = localStorage.getItem("mySongs");
            if (localSongs && JSON.parse(localSongs).length > 0) {
                content = [...JSON.parse(localSongs)];
            }
            let x = e.pageX;
            let y = e.pageY; //当前坐标
            let randContent = Math.ceil(Math.random() * content.length);
            let loveSpan = document.createElement("span");
            loveSpan.style.color = this.getRandom();
            /* 创建loveSpan */
            let body = document.body;
            let text = `❤《${content[randContent - 1]}》❤ `;
            loveSpan.innerHTML = text;
            loveSpan.className = "songText";
            loveSpan.style.top = y - 20 + "px";
            loveSpan.style.left = x - 50 + "px";
            loveSpan.style.animation = "remove 2s";
            loveSpan.style.position = "absolute";
            body.appendChild(loveSpan);
            let i = 0;
            setInterval(() => {
                loveSpan.style.top = y - 25 - i + "px";
                i++;
            }, 10);
            /* 销毁loveSpan */
            setTimeout(function () {
                loveSpan.remove();
            }, 1000);
        },
        /* 获取随机颜色 */
        getRandom() {
            let r = Number(Math.random() * 256);
            let g = Number(Math.random() * 256);
            let b = Number(Math.random() * 256);
            return `rgba(${r},${g},${b},0.5)`;
        },
    },
});
