import { defineStore } from 'pinia'

export const songsStore = defineStore('songs', {
  state: () => {
    return {
      songs: ["晴天", "青花瓷"]
    }
  },
  getters: {

  },
  actions: {

  }
})