import axios from 'axios';
import { config } from "@/config"





/**
 * 处理异常
 * @param {*} error 
 */
function httpErrorStatusHandle(error: Record<string,any>) {

  let message = '';
  if (error && error?.response) {
    switch (error.response.status) {
      case 302: message = '接口重定向了！'; break;
      case 400: message = '参数不正确！'; break;
      case 401: message = '您未登录，或者登录已经超时，请先登录！'; break;
      case 403: message = '您没有权限操作！'; break;
      case 404: message = `请求地址出错: ${error.response.config.url}`; break; // 在正确域名下
      case 408: message = '请求超时！'; break;
      case 409: message = '系统已存在相同数据！'; break;
      case 500: message = '服务器内部错误！'; break;
      case 501: message = '服务未实现！'; break;
      case 502: message = '网关错误！'; break;
      case 503: message = '服务不可用！'; break;
      case 504: message = '服务暂时无法访问，请稍后再试！'; break;
      case 505: message = 'HTTP版本不受支持！'; break;
      default: message = '异常问题，请联系管理员！'; break
    }
  }
  if (error.message.includes('timeout')) message = '网络请求超时！';
  if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '您断网了！';


  return { message, code: -1 }
}



function myAxios(axiosConfig:   Record<string,unknown>):Promise<Record<string,any>> {
  const service = axios.create({
    baseURL: config.baseURL, // 设置统一的请求前缀
    timeout: 10000, // 设置统一的超时时长
    data: axiosConfig?.data ?? {}
  });

  //响应拦截
  service.interceptors.response.use((response => {

    const { status = "", data = {}, statusText = "" } = response;
    const { code = "" } = data;
    if (Number(status) === 200 || statusText === "OK" || Number(code) === 200) {
      return Promise.resolve({ ...data, code: 1 })
    } else {
      return Promise.reject({ ...data, code: -1 });
    }
  }),
    error => {
      const data = httpErrorStatusHandle(error);
      return Promise.reject({ ...data, });
    }
  )

  return service(axiosConfig)
}

export default myAxios;

