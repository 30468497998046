import { defineComponent, ref, onMounted } from "vue";
import AddSong from "@/components/pages/Songs/AddSong.vue";
import { queryAllSongs, deleteSong } from '@/api/songs';
export default defineComponent({
    name: "Songs",
    setup() {
        const pageSize = ref(10);
        const songs = ref([]);
        const total = ref(0);
        const currentPage = ref(1);
        // 1. 创建一个组件的 ref 引用
        const addSongRef = ref({});
        /* 分页获取歌曲 */
        const getAllSongs = (params = {}) => {
            var _a, _b, _c;
            const payload = {
                paging: (_a = params.paging) !== null && _a !== void 0 ? _a : true,
                current: (_b = params.currentPage) !== null && _b !== void 0 ? _b : currentPage.value,
                pageSize: (_c = params.pageSize) !== null && _c !== void 0 ? _c : pageSize.value,
            };
            queryAllSongs(payload).then((res) => {
                const { code = "", message = [], total: dataTotal } = res;
                if (Number(code) > 0) {
                    songs.value = message;
                    total.value = dataTotal;
                }
            }).catch(error => { });
        };
        /* 新增，修改歌曲 */
        const showAddSongModal = (row = {}) => {
            /* 调用子组件的函数 */
            addSongRef.value.showAddSongModal(row);
            // addSongRef.value.addSongModal = true;
        };
        /* 删除歌曲 */
        const handleDelete = (row) => {
            deleteSong({ id: row._id }).then((res) => {
                const { code = "", message = [] } = res;
                if (Number(code) > 0) {
                    currentPage.value = 1;
                    getAllSongs({});
                    updateLocalStorage();
                }
            }).catch((err) => { });
        };
        /* 页码改变 */
        const currentChange = (current) => {
            currentPage.value = current;
            getAllSongs({
                paging: true,
                currentPage: current,
                pageSize: pageSize.value,
            });
        };
        /* 获取所有歌曲，更新localstorage */
        const updateLocalStorage = () => {
            queryAllSongs({ paging: false, }).then((res) => {
                const { code = "", message = [], total: dataTotal } = res;
                if (Number(code) > 0) {
                    localStorage.setItem("mySongs", JSON.stringify(message.map((item) => item.name)));
                }
            })
                .catch((err) => { });
        };
        getAllSongs({});
        onMounted(() => { });
        return {
            songs,
            pageSize,
            total,
            currentPage,
            currentChange,
            getAllSongs,
            handleDelete,
            showAddSongModal,
            updateLocalStorage,
            addSongRef,
        };
    },
    components: {
        AddSong,
    },
});
