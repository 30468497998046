import { defineComponent, ref, watch } from "vue";
import { mainStore } from "@/store";
// const store = mainStore();
// const text = ref("初始文本");
// const text1 = ref("初始文本1");
// const textList = ref(["1", "2"]);
// const updateContent = () => {
//   // store.content = "修改后的hello world";
//   store.content = "修改后的hello world";
// };
// const updateCount = () => {
//   store.count++;
// };
// const updatePatch = () => {
//   // store.$patch({
//   //   count: store.count + 1,
//   //   content: "批量修改content1",
//   // });
//   store.$patch((state) => {
//     state.count++, (state.content = "批量修改===箭头函数===content");
//   });
// };
// const updatePatchUseAction = () => {
//   store.changeAllState();
// };
// const updatePhone = () => {
//   store.phone = "13110649876";
// };
// const updateText = () => {
//   text.value = "修改后的文本值" + new Date().getTime()
// }
// const updateTextList = () => {
//   textList.value.push(`${new Date().getTime()}`)
// }
// watch([text, text1, textList.value], (newValue, oldValue) => {
//   console.log("🚀 ~ file: Test2.vue ~ line 61 ~ watch ~ newValue", newValue?.[2])
// })
export default defineComponent({
    name: "Test2",
    setup() {
        const store = mainStore();
        const text = ref("初始文本");
        const text1 = ref("初始文本1");
        const textList = ref(["1", "2"]);
        const updateContent = () => {
            // store.content = "修改后的hello world";
            store.content = "修改后的hello world";
        };
        const updateCount = () => {
            store.count++;
        };
        const updatePatch = () => {
            // store.$patch({
            //   count: store.count + 1,
            //   content: "批量修改content1",
            // });
            store.$patch((state) => {
                state.count++, (state.content = "批量修改===箭头函数===content");
            });
        };
        const updatePatchUseAction = () => {
            store.changeAllState();
        };
        const updatePhone = () => {
            store.phone = "13110649876";
        };
        const updateText = () => {
            text.value = "修改后的文本值" + new Date().getTime();
        };
        const updateTextList = () => {
            textList.value.push(`${new Date().getTime()}`);
        };
        watch([text, text1, textList.value], (newValue, oldValue) => {
            console.log("🚀 ~ file: Test2.vue ~ line 61 ~ watch ~ newValue", newValue === null || newValue === void 0 ? void 0 : newValue[2]);
        });
        return {
            store,
            text,
            text1,
            textList,
            updateContent,
            updateCount,
            updatePatch,
            updatePatchUseAction,
            updatePhone,
            updateText,
            updateTextList,
        };
    },
});
