import { registerMicroApps, start ,addGlobalUncaughtErrorHandler} from "qiankun";
registerMicroApps(
  [
  {
    name: "aistock_info_react", // app name registered
    // entry: "http://localhost:9040/aistock_info_react",
      // entry: "http://139.196.13.239:9040/:9040/aistock_info_react",
    // entry:"http://139.196.13.239:80/aistock_info_react",
    entry: "/aistock_info_react",
    container: "#app",
    activeRule: "/aistock_info_react/?"
  }
  ],
  {
    beforeLoad: (app: any) => {
      console.log("before load+++++++++++", app.name);
      return Promise.resolve();
    },
    beforeMount: (app: any) => {
      console.log("before mount----------", app.name);
      return Promise.resolve();
    },
    afterMount: (app: any) => {
      console.log("after mount============", app.name);
      return Promise.resolve();
    },
    afterUnmount: (app: any) => {
      console.log("after unmount===+++++----", app.name);
      return Promise.resolve();
    },
  }
  
);

// 启动 qiankun 应用
start({ sandbox: { experimentalStyleIsolation: true } });

// 添加全局异常捕获
addGlobalUncaughtErrorHandler((handler) => {
  console.log("异常捕获", handler);
});


