import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
/* es6 指定导入导出 */
// import { routes as routes_test } from "@/router/test"
/* es6 默认导入导出 */
import routes_test from "@/router/test"
import routes_songs from "./songs";
import Home from '../components/Home.vue'
import Songs from '../components/pages/Songs/Songs.vue'





const routes = [

  ...routes_test,
  ...routes_songs,

  // { path: '/:catchAll(.*)', redirect: '/' }, /* 默认跳转路由 */
  {
    path: '/',
    name: 'home',
    component: Home
  },
 

  //  {
  //   path: '/',
  //   name: '/',
  //   component: Songs
  // },
  //   {
  //   path: '/home',
  //   name: 'home',
  //   component: Home
  // },


]
export const router = createRouter({
  history: createWebHistory(),
  routes: routes  
})

