const baseURL = window.location.origin
// if (process.env.NODE_ENV === "development") {
//  baseURL = 'http://localhost:3000'
// } else {
//  baseURL = 'http://139.196.13.239:3000'
  
// }

export const config = {
  baseURL:baseURL+"/api/"
}



